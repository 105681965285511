'use client';

import { useScopedI18n } from '@/lib/i18n/client';
import { Event } from '@/services/events/get-eventos';
import { Loader2, Search } from 'lucide-react';

import { useHomeFilter } from '@/containers/home/home-filter/use-home-filter';

import { Form } from '@/components/form';
import { FormInputWrapper } from '@/components/form/form-wrapper';
import { Button } from '@/components/ui/button';
import { Container } from '@/components/ui/container';
import { Input } from '@/components/ui/input';

interface Props {
  events: Event[];
  className?: string;
}

export const HomeFilter = ({ events, className }: Props) => {
  const t = useScopedI18n('homeFilter');
  const { form, loading, onSubmit } = useHomeFilter();

  return (
    <Container className={className}>
      <Form form={form} onSubmit={onSubmit} className='flex'>
        {/* <FormInputWrapper
          input={Input}
          name='city'
          placeholder={t('city.placeholder')}
        />

        <FormInputWrapper
          input={DatePicker}
          name='date'
          placeholder={t('date.placeholder')}
          mode='range'
        /> */}

        <div className='flex w-full gap-y-1'>
          <FormInputWrapper
            input={Input}
            name='query'
            classNames={{
              container: 'w-full',
              input: 'rounded-r-none focus-visible:ring-0',
            }}
            placeholder={t('query.placeholder')}
          />

          {/* <FormInputWrapper
            input={DatePicker}
            name='date'
            placeholder={t('date.placeholder')}
            mode='range'
            className='lg:rounded-l-none lg:rounded-r-none'
            classNames={{
              container: 'w-full lg:w-2/3',
            }}
          /> */}

          {/* <FormInputWrapper
            input={Input}
            name='city'
            placeholder={t('city.placeholder')}
            classNames={{
              container: 'w-2/3 lg:w-2/3',
              input: 'lg:rounded-l-none rounded-r-none focus-visible:ring-0',
            }}
          /> */}

          <Button
            type='submit'
            className='w-1/3 rounded-l-none border border-primary max-sm:p-0 lg:w-52'
            disabled={loading}
          >
            {loading ? (
              <Loader2 className='mr-2 animate-spin' />
            ) : (
              <Search className='mr-2' />
            )}
            <span className='pt-0.5'>{t('submit.label')}</span>
          </Button>
        </div>
      </Form>
    </Container>
  );
};
