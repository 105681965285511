'use client';

import { cn } from '@/lib/cn';
import { useScopedI18n } from '@/lib/i18n/client';
import { BannerEvent } from '@/services/events/get-events-banner';
import {
  Calendar,
  CalendarXIcon,
  MapPin,
  SparklesIcon,
  Ticket,
  TicketCheck,
} from 'lucide-react';
import Link from 'next/link';
import 'swiper/css/navigation';
import { EffectCards, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

interface Props {
  events: BannerEvent[];
  className?: string;
  cliVerEveOculto?: boolean;
}

export const HomeSwiper = ({ events, className, cliVerEveOculto }: Props) => {
  const t = useScopedI18n('home');

  return (
    <Swiper
      effect='cards'
      grabCursor
      loop
      pagination={{ dynamicBullets: false, clickable: true }}
      modules={[Navigation, EffectCards, Pagination]}
      className={className}
      navigation
    >
      {events.map((event) => {
        let href;

        href =
          event.eventos > 1
            ? `/event-dates/${event.eveSlug}`
            : event.eveValidarTarjeta
              ? `/card-verification/${event.eveSlug}/${event.efeCodigoPrimeraDisponible}`
              : `/${event.efeModoCompra === 'map' || event.efeModoCompra === 'mapForm' ? 'map' : 'event'}/${event.eveSlug}/${event.efeCodigoPrimeraDisponible || 100}`;

        if (
          (event.eveSouldOut ||
            event.eveMuyPronto ||
            event.eveSoloTaquilla ||
            event.eveCancelado) &&
          !cliVerEveOculto
        ) {
          href = undefined;
        }

        const status = event.eveMuyPronto
          ? 'comingSoon'
          : event.eveCancelado
            ? 'cancelled'
            : event.eveSouldOut
              ? 'soldOut'
              : event.eveSoloTaquilla
                ? 'boxOffice'
                : event.eveEsPreventa
                  ? 'preSale'
                  : '';

        return (
          <SwiperSlide key={event.eveCodigo}>
            {status && (
              <EventTag className={statusMap[status]} type={status}>
                {t(status)}
              </EventTag>
            )}

            <Link href={href || '#'} className='max-md:pointer-events-none'>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={event.imgURL}
                alt={event.eveNombre}
                width={600}
                height={270}
                //priority={i === 0}
                //quality={100}
                className='min-h-48 rounded-t-3xl object-cover md:min-h-fit'
              />
            </Link>

            {/* <img
            src={event.imgSmallURL}
            alt={event.eveNombre}
            width={398}
            height={192}
            priority={i === 0}
            quality={100}
            className='min-h-48 rounded-t-3xl object-cover md:hidden'
          /> */}

            <div className='flex h-full flex-col justify-between p-4 md:mt-2'>
              <div>
                <h2 className='flex text-xl md:text-2xl'>
                  <Ticket className='mr-2 mt-0.5 h-5 w-5 md:mt-0 md:h-8 md:w-8' />
                  {event.eveNombre}
                </h2>

                <p className='mt-4 flex text-gray-400 md:mt-6 md:text-lg'>
                  <Calendar className='mr-2 mt-0.5 h-5 w-5 md:mt-1' />
                  {event.primeraFecha}
                </p>

                <p className='mt-1 flex text-gray-400 md:text-lg'>
                  <MapPin className='mr-2 mt-0.5 h-5 w-5 md:mt-1' />
                  {event.location}
                </p>
              </div>

              {href && (
                <div className='flex justify-end'>
                  <Button className='mt-2 md:mt-4' asChild>
                    <Link href={href || '#'}>
                      <TicketCheck className='mr-2' />

                      {t('buyTickets')}
                    </Link>
                  </Button>
                </div>
              )}
            </div>
          </SwiperSlide>
        );
      })}

      {events.length === 0 && (
        <SwiperSlide>
          <div className='mx-auto flex h-full max-w-lg flex-col items-center justify-center p-4 text-center'>
            <CalendarXIcon className='h-12 w-12 text-gray-400' />
            <p className='mt-6 text-xl font-bold text-gray-200'>
              {t('noEvents')}
            </p>

            <p className='mt-2 text-gray-400'>{t('noEventsDescription')}</p>

            <Separator className='my-6 bg-[#2b282c]' />

            <p className='text-sm text-gray-400'>{t('noEventsTeam')}</p>
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  );
};

interface EventTagProps {
  children: React.ReactNode;
  className?: string;
  type?: string;
}

const EventTag = ({ children, className, type }: EventTagProps) => (
  <span
    className={cn(
      'absolute right-0 top-0 flex rounded-bl-xl rounded-tr-xl px-4 py-1 text-sm font-semibold text-white',
      className,
    )}
  >
    {type === 'preSale' && <SparklesIcon className='mr-2' size={16} />}

    {children}
  </span>
);

const statusMap = {
  cancelled: 'bg-red-500',
  soldOut: 'bg-primary',
  comingSoon: 'bg-blue-600',
  boxOffice: 'bg-blue-500',
  preSale: 'fancy-gradient',
};
